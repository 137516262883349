import { httpClient } from '@/core/services/http-client.service.ts';
import type {
  TConfirmEmailRequestData,
  TEmailForm,
  TLoginData,
  TLoginResponse,
  TRegisterData,
  TResetPasswordData,
} from '../types.ts';

export const loginFetch = async (data: TLoginData): Promise<TLoginResponse> => {
  try {
    const response = await httpClient.post<TLoginResponse>('/auth/login', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logoutFetch = async (): Promise<string> => {
  try {
    const response = await httpClient.post<string>('/auth/logout');

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordFetch = async ({
  email,
}: TEmailForm): Promise<string> => {
  try {
    const response = await httpClient.post<string>('/auth/forgotpassword', {
      email,
      isOperator: false,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordFetch = async (
  data: TResetPasswordData,
): Promise<string> => {
  try {
    const response = await httpClient.post<string>('/auth/resetpassword', data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerAccountFetch = async (
  data: TRegisterData,
): Promise<string> => {
  try {
    const response = await httpClient.post<string>(
      '/auth/registerprofile',
      data,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkEmail = async (userName: string): Promise<boolean> => {
  try {
    return (
      await httpClient.get<boolean>(`/auth/checkemail?username=${userName}`)
    ).data;
  } catch (error) {
    throw error;
  }
};

export const sendConfirmEmail = async (data: TEmailForm): Promise<boolean> => {
  try {
    return (
      await httpClient.get<boolean>(
        `/auth/requestemailconfirm?email=${data.email}`,
      )
    ).data;
  } catch (error) {
    throw error;
  }
};

export const confirmEmailFetch = async ({
  userId,
  code,
}: TConfirmEmailRequestData): Promise<string> => {
  try {
    const response = await httpClient.get<string>(
      `/auth/confirm?userId=${userId}&code=${code}`,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkAuthFetch = async (): Promise<boolean> => {
  try {
    const response = await httpClient.get<boolean>(`/auth/checkauth`);

    if (!response) {
      return false;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};
